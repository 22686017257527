@import 'mixins';

@include link;

:root {
  @each $breakpoint, $value in $grid-breakpoints {
    --#{$breakpoint}-breakpoint: #{$value};
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 20px;
}

body {
  background-color: $brand-coloured-gray;
  margin-right: auto;
  margin-left: auto;
  max-width: $body-max-with;
}

.no-last-child-margin-bottom {
  * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

a {
  &.no-link-style {
    @include link-unset;
  }
}

a[target='_blank'] {
  &:not(.no-link-style) {
    &::after {
      font-size: 0.8em;
      margin-left: 0.5em;
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-weight: 900;
      content: '\f35d';
    }
  }
}

dl {
  * {
    font-weight: normal;
  }

  dt {
    margin: 0 $spacer 0 0;
    padding: 0;
  }

  dd {
    margin: 0;
    padding: 0;
  }

  &.inline {
    display: grid;
    grid-template-columns: max-content auto;

    dt {
      grid-column-start: 1;
    }

    dd {
      grid-column-start: 2;
    }
  }
}

.w-60 {
  width: 60%;
}

.login-form, .reset-password-form, .register-form {
  .input-group-text {
    width: 50px;
  }
}

