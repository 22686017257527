[class^="row"] {
  [class^="col"] {
    &:nth-child(odd) {
      .teaser-element {
        &.alternate {
          .teaser-element-txt-wrapper {
            align-self: flex-start;
          }
        }
      }
    }

    &:nth-child(even) {
      .teaser-element {
        &.alternate {
          .teaser-element-img-wrapper {
            align-self: flex-start;
          }

          .teaser-element-txt-wrapper {
            align-self: flex-end;
          }
        }
      }
    }

    .teaser-element {
      position: relative;

      .teaser-element-img-wrapper {
        align-self: flex-end;
        position: relative;
        width: 80%;

        .teaser-element-img-ratios {
          --bs-aspect-ratio: #{map-get($aspect-ratios, '1x1')};

          @include media-breakpoint-up(sm) {
            --bs-aspect-ratio: #{map-get($aspect-ratios, '4x3')};
          }

          @include media-breakpoint-up(xl) {
            --bs-aspect-ratio: #{map-get($aspect-ratios, '16x9')};

            &.teaser-element-img-ratios-small {
              --bs-aspect-ratio: #{map-get($aspect-ratios, '4x3')};
            }
          }
        }
      }

      .teaser-element-txt-wrapper {
        position: absolute;
        width: 80%;

        @include media-breakpoint-up(md) {
          width: 60%;
        }

        .teaser-element-txt-heading {
          @include media-breakpoint-up(md) {
            @include font-size($h3-font-size);
          }
        }
      }
    }
  }
}
